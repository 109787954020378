import {
  Input,
  Row,
  Col,
  Radio,
  Divider,
  DatePicker,
  Space,
  Checkbox,
  Select,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import { FaUserAlt } from "react-icons/fa";
import { IoInformationCircleSharp } from "react-icons/io5";
import { GoDeviceMobile, GoPackage } from "react-icons/go";
import { MdEmail } from "react-icons/md";
import "./carform.scss";
import moment from "moment";
import { colorScheme } from "../../contants/variables";
import { getCommaSeparatedTwoDecimalsNumber } from "../../stores/utils/formatNumber";

const FieldSuffix = (props) => {
  const { TooltipData } = props;
  return (
    <Tooltip
      title={() => {
        return <TooltipData />;
      }}
      placement="bottomRight"
    >
      <IoInformationCircleSharp
        color={colorScheme.primary}
        style={{ fontSize: "20px" }}
      />
    </Tooltip>
  );
};

const CarDetails = (props) => {

  const [depreciation, setDepreciation] = useState(null);

  const {
    form,
    setForm,
    cities,
    brands,
    models,
    dealers,
    packages,
    isEdit,
    vehicleTypes,
    fuelTypes,
    ownerIdTypes,
  } = props;

  useEffect(() => {
    if(form.coeExpiryDate && isEdit) {
      let coeDate = moment(form.coeExpiryDate);
      let nowDate = moment();
      let years = coeDate.diff(nowDate, 'years');
      nowDate.add(years, 'years');
      let months = coeDate.diff(nowDate, 'months');
      nowDate.add(months, 'months');
      let days = coeDate.diff(nowDate, 'days');
      console.log(years + ' years ' + months + ' months ' + days + ' days');
      form.coeLeftDate = years + ' years ' + months + ' months ' + days + ' days'
    }
  }, [form.coeExpiryDate, isEdit])

  const handleFieldChange = (name, e) => {

    if(name == "engineCap") {
      let engineCap = e.target.value == "" ? 0 : e.target.value;
      if(engineCap.length <= 5 || engineCap == 0) {
        setForm({
          ...form,
          [name]: parseInt(engineCap),
        });
      }
    } else if(name == "power") {
      let power = e.target.value == "" ? 0 : e.target.value;
      if(power.length <= 6 || power == 0) {
        setForm({
          ...form,
          [name]: parseInt(power),
        });
        console.log(parseInt(power));
      }
    } else if(name == "manufacturedYear") {
      let manufacturedYear = e.target.value == "" ? 0 : e.target.value;
      if(manufacturedYear.length <= 6 || manufacturedYear == 0) {
        setForm({
          ...form,
          [name]: parseInt(manufacturedYear),
        });
        console.log(parseInt(manufacturedYear));
      }
    } else if(name == "numberOfOwners") {
      let numberOfOwners = e.target.value == "" ? 0 : e.target.value;
      if(numberOfOwners.length <= 6 || numberOfOwners == 0) {
        setForm({
          ...form,
          [name]: parseInt(numberOfOwners),
        });
        console.log(parseInt(numberOfOwners));
      }
    } else {
      setForm({
        ...form,
        [name]: e.target.value,
      });
    }

  };
  
  const handleNumberFieldChange = (name, e, maxLength = 7) => {

    if(name == "arf") {
      let arf = e;
      if(arf && form.askingPrice) {
        calculateDepreciation(form.askingPrice, arf)
      }
    }
    if(name == 'askingPrice') {
      // console.log(e)
      let askingPrice = e;
      if(form.arf && askingPrice) {
        calculateDepreciation(askingPrice, form.arf)
      }
    }

    if(e.length <= maxLength || e == 0) {
      setForm({
        ...form,
        [name]: e,
      });
    }
    
  };

  const handleSelectPackage = (value) => {
    setForm({ ...form, packageId: value });
  };

  const onDateFieldChange = (name, dateString) => {

    if(name == "registrationDate") {
      let regDate = moment(dateString);
      let coeDate = regDate.add(10, 'y').subtract(1, 'days');
      let nowDate = moment();
      let years = coeDate.diff(nowDate, 'years');
      nowDate.add(years, 'years');
      let months = coeDate.diff(nowDate, 'months');
      nowDate.add(months, 'months');
      let days = coeDate.diff(nowDate, 'days');
      // console.log(years + ' years ' + months + ' months ' + days + ' days');
      form.coeLeftDate = years + ' years ' + months + ' months ' + days + ' days'
      form.coeExpiryDate = coeDate;
    }

    setForm({ ...form, [name]: dateString });
  };

  const onCheckboxChange = (name, e) => {
    setForm({
      ...form,
      [name]: e.target.checked,
    });
  };

  const handleDropdownChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const calculateDepreciation = (askingPrice, arfVAlue) => {
    form.depreciationValue = "";
    let coeDate = moment(form.coeExpiryDate);
    let nowDate = moment();
    let leftMonths = coeDate.diff(nowDate, 'months');
    console.log(leftMonths);

    let halfArf = arfVAlue / 2;
    let leftPrice = askingPrice - halfArf;
    let totalMonthPrice = leftPrice / leftMonths;
    let result = totalMonthPrice * 12;
    // console.log(result);
    form.depreciationValue = result.toFixed(0);
  }

  return (
    <div>
      <div className="header-container">
        <p className="header">Dealer Personal Details</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Name <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.fullname || undefined}
              loading={dealers.loading}
              onChange={(value) => handleDropdownChange("dealerId", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {[
                {
                  user_first_name: "NOT ON THE LIST DEALER",
                  user_last_name: "",
                  _id: "0",
                },
                {
                  user_first_name: "NOT ON THE LIST DIRECT SELLER",
                  user_last_name: "",
                  _id: "1",
                },
                ...dealers.data,
              ].map((el, i) => (
                <Select.Option key={i} value={el._id}>
                  {`${el.user_first_name} ${el.user_last_name}`}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Mobile Number <span>*</span> :{" "}
            </p>
            <Input
              value={form.dealerMobileNumber}
              prefix={<GoDeviceMobile />}
              onChange={(text) => handleFieldChange("dealerMobileNumber", text)}
              disabled={
                form.dealerId === "0" || form.dealerId === "1" ? false : true
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Email:</p>
            <Input
              value={form.dealerEmail}
              prefix={<MdEmail />}
              onChange={(text) => handleFieldChange("dealerEmail", text)}
              disabled={
                form.dealerId === "0" || form.dealerId === "1" ? false : true
              }
            />
          </div>
        </Col>
      </Row>

      {(form.dealerId === "0" || form.dealerId === "1") && (
        <Row>
          <Col span={8}>
            <div className="form-fields">
              <p className="form-field-label">
                First Name <span>*</span> :{" "}
              </p>
              <Input
                value={form.dealerFirstName}
                onChange={(text) => handleFieldChange("dealerFirstName", text)}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="form-fields">
              <p className="form-field-label">Last Name: </p>
              <Input
                value={form.dealerLastName}
                onChange={(text) => handleFieldChange("dealerLastName", text)}
              />
            </div>
          </Col>
        </Row>
      )}

      <Divider />

      {form.role === "Advertiser" ? (
        <div>
          <div className="header-container">
            <p className="header">Package Options</p>
            <p className="sub-header">
              note: (*) for advertisers they must select a plan
            </p>
          </div>
          <Row>
            {packages.map((item, index) => (
              <Col span={8} key={index}>
                <div
                  className={`package-card-container ${
                    form.packageId === item._id ? "active" : ""
                  }`}
                  onClick={() => handleSelectPackage(item._id)}
                >
                  <div className="package-title">
                    <p>{item.package_name}</p>
                    <p>S$ {item.package_cost}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Divider />
        </div>
      ) : null}

      <div className="header-container">
        <p className="header">Car Details</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Car Plate Number <span>*</span> :{" "}
            </p>
            <Input
              value={form.plateNumber}
              onChange={(text) => handleFieldChange("plateNumber", text)}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>
                      This car plate number field will not be shown in the ad.
                      It is for verification purpose.
                    </p>
                  )}
                />
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Owner ID Type <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.ownerIdType || undefined}
              onChange={(value) => handleDropdownChange("ownerIdType", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {ownerIdTypes.map((el, i) => (
                <Select.Option key={i} value={el.label}>
                  {el.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Owner ID <span>*</span> :{" "}
            </p>
            <Input
              maxLength={4}
              value={form.ownerId}
              onChange={(text) => handleFieldChange("ownerId", text)}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>
                      The identification number of the registered owner of
                      vehicle.
                      <br />
                      We only require the last 4 characters.
                      <br />
                      <br />
                      eg: If the identification number is S1234567A, simply
                      input 567A"
                    </p>
                  )}
                />
              }
            />
          </div>
        </Col>
      </Row>

      {/* <Row>
                <div className='form-radio' style={{margin:"10px 0px"}}>
                    <Radio.Group
                        onChange={(e)=>handleFieldChange('isManualInput' , e)} value={form.isManualInput}
                    >
                        <Radio value={0}>Use my carplate number and owner ID to fill my car details automatically</Radio>
                        <Radio value={1}>I wish to manually input all my car details</Radio>
                    </Radio.Group>
                </div>
            </Row> */}

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Registration Date <span>*</span> :{" "}
            </p>
            <Space>
              <div className="datepicker">
                <DatePicker
                  format="DD-MMM-YYYY"
                  style={{ width: "100%" }}
                  value={
                    isNaN(Date.parse(form.registrationDate)) ||
                    !form.registrationDate
                      ? moment('01/01/2010', 'DD-MM-YYYY')
                      : moment(form.registrationDate)
                  }
                  onChange={(date, dateString) =>
                    onDateFieldChange("registrationDate", dateString)
                  }
                />
              </div>
            </Space>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              COE Expiry Date <span>*</span> :{" "}
            </p>
            <Space>
              <div className="datepicker">
                <DatePicker
                  format="DD-MMM-YYYY"
                  style={{ width: "100%" }}
                  value={form.coeExpiryDate ? moment(form.coeExpiryDate) : null}
                  onChange={(date, dateString) =>
                    onDateFieldChange("coeExpiryDate", dateString)
                  }
                />
              </div>
            </Space>
          </div>
        </Col>
        <Col span={8}>
            <div className="form-fields">
              <p className="form-field-label">
                COE Left Date
              </p>
              <Input
                value={form.coeLeftDate}
                disabled
              />
            </div>
        </Col>
      </Row>

      <Row>
      <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              COE <span>*</span> :{" "}
            </p>
            <Input
              // type="number"
              // min="0"
              prefix={
                <div className="fieldPrefix">
                  <p>S$</p>
                </div>
              }
              value={getCommaSeparatedTwoDecimalsNumber(form.coe)}
              onChange={(e) => {
                handleNumberFieldChange(
                  "coe",
                  e.target.value == "" ? 0 : e.target.value.split(",").join("")
                );
              }}
              // value={form.coe}
              // onChange={(text) => handleFieldChange("coe", text)}
              // disabled = {!form.isManualInput}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              OMV <span>*</span> :{" "}
            </p>
            <Input
              // type="number"
              // min="0"
              prefix={
                <div className="fieldPrefix">
                  <p>S$</p>
                </div>
              }
              value={getCommaSeparatedTwoDecimalsNumber(form.omv)}
              onChange={(e) => {
                handleNumberFieldChange(
                  "omv",
                  e.target.value == "" ? 0 : e.target.value.split(",").join("")
                );
              }}
              // value={form.omv}
              // onChange={(text) => handleFieldChange("omv", text)}
              // disabled = {!form.isManualInput}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              ARF <span>*</span> :{" "}
            </p>
            <Input
              // type="number"
              // min="0"
              prefix={
                <div className="fieldPrefix">
                  <p>S$</p>
                </div>
              }
              value={getCommaSeparatedTwoDecimalsNumber(form.arf)}
              onChange={(e) => {
                handleNumberFieldChange(
                  "arf",
                  e.target.value == "" ? 0 : e.target.value.split(",").join("")
                );
              }}
              // value={form.arf}
              // onChange={(text) => handleFieldChange("arf", text)}
              // disabled = {!form.isManualInput}
            />
          </div>
        </Col>
        
      </Row>

      <Row>
      <Col span={8}>
        <div className="form-fields">
          <p className="form-field-label">
            Selling Price <span>*</span> :{" "}
          </p>
          <Input
            // type="number"
            // min="0"
            // step="1"
            prefix={
              <div className="fieldPrefix">
                <p>S$</p>
              </div>
            }
            value={getCommaSeparatedTwoDecimalsNumber(form.askingPrice)}
            onChange={(e) => {
              handleNumberFieldChange(
                "askingPrice",
                e.target.value == "" ? 0 : e.target.value.split(",").join("")
              );
            }}
            // value={Number.parseInt(form.askingPrice).toLocaleString()}
            // onChange={(text) => handleFieldChange("askingPrice", text)}
          />
        </div>
      </Col>
      <Col span={8}>
        <div className="form-fields">
          <p className="form-field-label"> Monthly Instalment : </p>
          <Input
            // type="number"
            // min="0"
            // step="1"
            prefix={
              <div className="fieldPrefix">
                <p>S$</p>
              </div>
            }
            value={getCommaSeparatedTwoDecimalsNumber(form.monthly_installment_price)}
            onChange={(e) => {
              handleNumberFieldChange(
                "monthly_installment_price",
                e.target.value == "" ? 0 : e.target.value.split(",").join(""),
                5
              );
            }}
            suffix={
              <FieldSuffix
                TooltipData={() => (
                  <p style={{ fontSize: "9px" }}>You may enter instalment plan.</p>
                )}
              />
            }
          />
        </div>
      </Col>
      <Col span={8}>
          <div className="form-fields">
              <p className="form-field-label">Depreciation Value : </p>
              <Input
                // type="number"
                // min="0"
                // step="1"
                value={getCommaSeparatedTwoDecimalsNumber(form.depreciationValue)}
                onChange={(e) => {
                  handleNumberFieldChange(
                    "depreciationValue",
                    e.target.value === "" ? 0 : e.target.value.split(",").join("")
                  );
                }}
                // value={form.depreciationValue}
                // onChange={(text) => handleFieldChange("depreciationValue", text)}
                
              />
            </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Deregistered Value : </p>
            <Input
              value={form.deregValue}
              onChange={(text) => handleFieldChange("deregValue", text)}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
            <div className="form-fields">
              <p className="form-field-label">Road Tax : </p>
              <Input
                // value={form.roadTax}
                // onChange={(text) => handleFieldChange("roadTax", text)}
                prefix={
                  <div className="fieldPrefix">
                    <p>S$</p>
                  </div>
                }
                value={getCommaSeparatedTwoDecimalsNumber(form.roadTax)}
                onChange={(e) => {
                  handleNumberFieldChange(
                    "roadTax",
                    e.target.value == "" ? 0 : e.target.value.split(",").join("")
                  );
                }}
              />
            </div>
          </Col>
          <Col span={8}>
              <div className="form-fields" style={{ marginTop: "10px" }}>
                <p>
                  For verifying the COE,ARF,OMV kindly visit{" "}
                  <a href="https://onemotoring.lta.gov.sg/" target="_blank">
                    One Motoring
                  </a>
                </p>
              </div>
          </Col>
      </Row>

      <Divider />

      <div className="header-container">
        <p className="header">Car Informations</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Car Name <span>*</span> :{" "}
            </p>
            <Input
              value={form.carName}
              onChange={(text) => handleFieldChange("carName", text)}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>
                      Name of the car to be displayed in website
                      <br />
                      eg: For SALE Toyota 40% Discount.
                    </p>
                  )}
                />
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Car Brand <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.carBrand || undefined}
              loading={brands.loading}
              onChange={(value) => handleDropdownChange("carBrand", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {brands.data.map((el, i) => (
                <Select.Option key={i} value={el._id}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Car Model <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.carModel || undefined}
              disabled={!models.data.length}
              loading={models.loading && form.carBrand}
              onChange={(value) => handleDropdownChange("carModel", value)}
              style={{ width: "100%" }}
              placeholder={
                models.loading
                  ? `Fetching list of models`
                  : !models.data.length
                  ? `No model available in this brand`
                  : `Search to Select`
              }
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {models.data.map((el, i) => (
                <Select.Option key={i} value={el._id}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Engine Cap <span>*</span> :{" "}
            </p>
            <Input
              maxLength={5}
              type="number"
              min="0"
              step="1"
              value={form.engineCap}
              onChange={(text) => handleFieldChange("engineCap", text)}
              // disabled = {!form.isManualInput}
              suffix={
                <div className="fieldPrefix">
                  <p>CC</p>
                </div>
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Power : </p>
            <Input
              maxLength={6}
              type="number"
              min="0"
              step="1"
              value={form.power}
              onChange={(text) => handleFieldChange("power", text)}
              suffix={
                <div className="fieldPrefix">
                  <p>kW</p>
                </div>
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Vehicle / Body Type : </p>
            <Select
              showSearch
              value={form.vehicleType || undefined}
              onChange={(value) => handleDropdownChange("vehicleType", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {vehicleTypes.map((vehicle, i) => (
                <Select.Option key={i} value={vehicle}>
                  {vehicle}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        {/* <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Car Condition <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.condition || undefined}
              onChange={(value) => handleDropdownChange("condition", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value="Used"> Used Car</Select.Option>
              <Select.Option value="New"> Brand New Car</Select.Option>
            </Select>
          </div>
        </Col> */}
      </Row>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Ownership <span>*</span> :{" "}
            </p>
            <Input
              type="number"
              min="0"
              step="1"
              value={form.numberOfOwners}
              onChange={(text) => handleFieldChange("numberOfOwners", text)}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>
                      If brand new put 0 , else put the number of owners
                    </p>
                  )}
                />
              }
            />
          </div>
        </Col>

        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              {/* Edition Year Manufactured <span>*</span> :{" "} */}
              Manufacturing Year <span>*</span> :{" "}
            </p>
            <Input
              placeholder="YYYY"
              type="number"
              value={form.manufacturedYear}
              onChange={(text) => handleFieldChange("manufacturedYear", text)}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>
                      The manufactured year of the car
                      <br />
                      e.g : 2020
                    </p>
                  )}
                />
              }
            />
          </div>
        </Col>

        {/* <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Curb Weight : </p>
            <Input
              type="number"
              min="0"
              step="1"
              value={form.curbWeight}
              onChange={(text) => handleFieldChange("curbWeight", text)}
            />
          </div>
        </Col> */}

        {/* <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Depreciation Value : </p>
            <Input
              type="number"
              min="0"
              step="1"
              value={form.depreciationValue}
              onChange={(text) => handleFieldChange("depreciationValue", text)}
            />
          </div>
        </Col> */}

        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Color : </p>
            <Select
              showSearch
              value={form.color ?? undefined}
              onChange={(value) => handleDropdownChange("color", value)}
              style={{ width: "100%" }}
              placeholder="Select Color."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value="black">Black</Select.Option>
              <Select.Option value="grey">Grey</Select.Option>
              <Select.Option value="white">White</Select.Option>
              <Select.Option value="gold">Gold</Select.Option>
              <Select.Option value="red">Red</Select.Option>
              <Select.Option value="blue">Blue</Select.Option>
              <Select.Option value="yellow">Yellow</Select.Option>
              <Select.Option value="green">Green</Select.Option>
              <Select.Option value="orange">Orange</Select.Option>
              <Select.Option value="pink">Pink</Select.Option>
              <Select.Option value="purple">Purple</Select.Option>
              <Select.Option value="brown">Brown</Select.Option>
              <Select.Option value="silver">Silver</Select.Option>
              <Select.Option value="maroon">Maroon</Select.Option>
            </Select>
          </div>
        </Col>

        {/* <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Youtube Video : </p>
            <Input
              value={form.youtubeVideo}
              onChange={(text) => handleFieldChange("youtubeVideo", text)}
            />
          </div>
        </Col>

        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Tiktok Video : </p>
            <Input
              value={form.tiktokVideo}
              onChange={(text) => handleFieldChange("tiktokVideo", text)}
            />
          </div>
        </Col> */}
      </Row>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <div className="label-with-tooltip">
              <p className="form-field-label"> Option Label 1 :</p>
              <FieldSuffix
                TooltipData={() => (
                  <p style={{ fontSize: "9px" }}>
                    Label shall be displayed on the picture of car.
                  </p>
                )}
              />
            </div>
            <Select
              value={form.option_label_1}
              onChange={(value) => handleDropdownChange("option_label_1", value)}
              style={{ width: "100%" }}
              placeholder="-- Select --"
              options={[
                {value: 'Negotiable', label: 'Negotiable'},
                {value: 'Test Drive Unit', label: 'Test Drive Unit'},
                {value: 'Showroom Unit', label: 'Showroom Unit'},
                {value: 'Consignment', label: 'Consignment'},
              ]}
              allowClear
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <div className="label-with-tooltip">
              <p className="form-field-label"> Option Label 2 :</p>
              <FieldSuffix
                TooltipData={() => (
                  <p style={{ fontSize: "9px" }}>
                    Label shall be displayed on the picture of car.
                  </p>
                )}
              />
            </div>
            <Select
              value={form.option_label_2}
              onChange={(value) => handleDropdownChange("option_label_2", value)}
              style={{ width: "100%" }}
              placeholder="-- Select --"
              options={[
                {value: '1 Owner', label: '1 Owner'},
                {value: 'Accident Free', label: 'Accident Free'},
                {value: 'Doctor', label: 'Doctor'},
                {value: 'Laywer', label: 'Laywer'},
                {value: 'Expat', label: 'Expat'},
                {value: 'Body Export', label: 'Body Export'},
              ]}
              allowClear
            />
          </div>
        </Col>
        {/* <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label"> Option Label 3 :</p>
            <Select
              value={form.option_label_3}
              onChange={(value) => handleDropdownChange("option_label_3", value)}
              style={{ width: "100%" }}
              placeholder="-- Select --"
              options={[
                {value: '1 Owner', label: '1 Owner'},
                {value: '2 Owners', label: '2 Owners'},
                {value: 'Accident Free', label: 'Accident Free'},
                {value: 'Body Export', label: 'Body Export'},
              ]}
              allowClear
            />
          </div>
        </Col> */}
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Video Link : </p>
            <Input
              value={form.youtubeVideo}
              onChange={(text) => handleFieldChange("youtubeVideo", text)}
              placeholder="Youtube or Tiktok Video Link"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div className="form-fields">
            <Checkbox
              valuePropName="checked"
              checked={form.isCommercialVehicle}
              onChange={(e) => onCheckboxChange("isCommercialVehicle", e)}
            >
              Is Commercial Vehicle
            </Checkbox>
          </div>
        </Col>
        
        <Col span={8}>
          <div className="form-fields">
            <Checkbox
              valuePropName="checked"
              checked={form.isVerified}
              onChange={(e) => onCheckboxChange("isVerified", e)}
            >
              Is Verified
            </Checkbox>
          </div>
        </Col>
        
        <Col span={8}>
          <div className="form-fields">
            <Checkbox
              valuePropName="checked"
              checked={form.isSendEmail}
              onChange={(e) => onCheckboxChange("isSendEmail", e)}
            >
              Is Send Email
            </Checkbox>
          </div>
        </Col>
      </Row>

      <Divider />

      <div className="header-container">
        <p className="header">Viewing Location</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Pickup Address : </p>
            <Input
              value={form.address}
              onChange={(text) => handleFieldChange("address", text)}
              suffix={
                <FieldSuffix
                  TooltipData={() => (
                    <p style={{ fontSize: "9px" }}>
                      Complete address where to pick-up the car.
                      <br />
                      This is an important field for filtering cars in the
                      system and analytics.
                    </p>
                  )}
                />
              }
            />
          </div>
        </Col>

        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              City , Region <span>*</span> :{" "}
            </p>
            <Select
              showSearch
              value={form.cityId || undefined}
              disabled={!cities.data}
              loading={cities.loading}
              onChange={(value) => handleDropdownChange("cityId", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {cities.data.map((el, i) => (
                <Select.Option key={i} value={el._id}>
                  {`${el.city}, ${el.region_name}`}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

      <Divider />

      <div className="header-container">
        <p className="header">Car Specifications</p>
        <p className="sub-header">
          note: (*) fields are required to be filled up
        </p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Transmission <span></span> :{" "}
            </p>
            <Select
              showSearch
              value={form.transmission || undefined}
              onChange={(value) => handleDropdownChange("transmission", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              <Select.Option value={"Automatic"}>Automatic</Select.Option>
              <Select.Option value={"Manual"}>Manual</Select.Option>
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Fuel Type <span></span> :{" "}
            </p>
            <Select
              showSearch
              value={form.fuelType || undefined}
              onChange={(value) => handleDropdownChange("fuelType", value)}
              style={{ width: "100%" }}
              placeholder="Search to Select."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase())
              }
            >
              {fuelTypes.map((fuel, i) => (
                <Select.Option key={i} value={fuel}>
                  {fuel}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Mileage <span>*</span> :{" "}
            </p>
            <Input
              // type="number"
              // min="0"
              // step="1"
              value={getCommaSeparatedTwoDecimalsNumber(form.mileage)}
              onChange={(e) => {
                handleNumberFieldChange(
                  "mileage",
                  e.target.value == "" ? 0 : e.target.value.split(",").join("")
                );
              }}
              // value={form.mileage}
              // onChange={(text) => handleFieldChange("mileage", text)}
              suffix={
                <div className="fieldPrefix">
                  <p>km</p>
                </div>
              }
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <div className="label-with-tooltip">
              <p className="form-field-label">
                Description <span>*</span> :{" "}
              </p>
              <FieldSuffix
                TooltipData={() => (
                  <p style={{ fontSize: "9px" }}>
                    Description will appear in the main page, so it is the most
                    important comment to add.
                    <br />
                    <br />
                    eg: Urgent sales; 1 careful owner; Seldom driven; high Parf
                    rebate; New road tax; Regularly servicing at Agent; Price
                    negotiable; Please call to arrange a test-drive etc...
                  </p>
                )}
              />
            </div>
            <Input.TextArea
              rows={8}
              value={form.description}
              onChange={(text) => handleFieldChange("description", text)}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <div className="label-with-tooltip">
              <p className="form-field-label">
                Features <span>*</span> :{" "}
              </p>
              <FieldSuffix
                TooltipData={() => (
                  <p style={{ fontSize: "9px" }}>
                    Features refers to functions in-built in your vehicle.
                    <br />
                    <br />
                    eg: ABS, EBD, Air-Bags, Sun-roof, Immobilizer, Keyless
                    Entry, VVT-i engine, Turbo-charger etc...
                  </p>
                )}
              />
            </div>
            <Input.TextArea
              rows={8}
              value={form.features}
              onChange={(text) => handleFieldChange("features", text)}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <div className="label-with-tooltip">
              <p className="form-field-label">
                Accessories <span>*</span> :{" "}
              </p>
              <FieldSuffix
                TooltipData={() => (
                  <p style={{ fontSize: "9px" }}>
                    Accessories refers to additional luxuries to your vehicle.
                    <br />
                    <br />
                    eg: Body kit, Spoiler, Leather Seats, Sports Rim,
                    CD-Changer, Reverse Sensors, Sports Seats, Racing clutch,
                    V-kool, HID headlights, VCD player, LCD TV etc...
                  </p>
                )}
              />
            </div>
            <Input.TextArea
              rows={8}
              value={form.accessories}
              onChange={(text) => handleFieldChange("accessories", text)}
            />
          </div>
        </Col>
      </Row>

      <Divider />

      <div className="header-container">
        <p className="header">Optional Details</p>
      </div>

      <Row>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Vehicle Number : </p>
            <Input
              value={form.vehicleNumber}
              onChange={(text) => handleFieldChange("vehicleNumber", text)}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Vehicle Unit Number : </p>
            <Input
              value={form.vehicleUnitNumber}
              onChange={(text) => handleFieldChange("vehicleUnitNumber", text)}
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Vehicle Reference Number : </p>
            <Input
              value={form.vehicleReferenceNumber}
              onChange={(text) =>
                handleFieldChange("vehicleReferenceNumber", text)
              }
            />
          </div>
        </Col>
        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">
              Vehicle Registration Card Number :{" "}
            </p>
            <Input
              value={form.vehicleRegistrationCardNumber}
              onChange={(text) =>
                handleFieldChange("vehicleRegistrationCardNumber", text)
              }
            />
          </div>
        </Col>
        {/* <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Dereg Value : </p>
            <Input
              value={form.deregValue}
              onChange={(text) => handleFieldChange("deregValue", text)}
            />
          </div>
        </Col>

        <Col span={8}>
          <div className="form-fields">
            <p className="form-field-label">Road Tax : </p>
            <Input
              value={form.roadTax}
              onChange={(text) => handleFieldChange("roadTax", text)}
            />
          </div>
        </Col> */}

        <Col span={8}>
          <div className="form-fields" style={{ margin: "32px 0px" }}>
            <Checkbox
              valuePropName="checked"
              checked={form.isOffPeak}
              onChange={(e) => onCheckboxChange("isOffPeak", e)}
            >
              Tick if the car is an Off-Peak Car
            </Checkbox>
          </div>
        </Col>
        {/* <Col span={8}>
                    <div className='form-radio' style={{margin:"10px 0px"}}>
                        <Radio.Group
                            onChange={(e)=>handleFieldChange('offPeakOption' , e)} value={form.offPeakOption}
                            disabled={!form.isOffPeak}
                        >
                            <Radio value={1}>Old OPC scheme (Use half-day on Saturdays)</Radio>
                            <Radio value={2}>Revised OPC scheme (Use full-day on Saturdays)</Radio>
                            <Radio value={3}>Normal car converted to Revised OPC scheme</Radio>
                        </Radio.Group>
                    </div>
                </Col> */}
        {/* <Col span={8}>
                    <div className='form-fields' style={{margin:"20px 0px"}}>
                        <Checkbox
                            valuePropName="checked"
                            value={form.isRental}
                            onChange={(e)=>onCheckboxChange('isRental',e)}
                        >
                            Tick if car is for rent.
                        </Checkbox>
                    </div>
                </Col> */}
      </Row>
    </div>
  );
};

export default CarDetails;
